<!-- npm install screenfull@5  -->
<template>
  <div class="home_div">
    <div>
      <button @click="tripRutren" v-if="!dataSoureSwitch">返回</button>
      <button @click="click">全屏</button>
      <button @click="changeShowButton" v-if="!dataSoureSwitch">选择景点</button>
      <button @click="inputDataButton" v-if="dataSoureSwitch">输入景点</button>
      <!-- <button @click="removeMarker">取消全部标记</button> -->
      <!-- <el-input v-model="cityinfo">地址</el-input> -->
      <!-- <el-button @click="onSearch">搜索</el-button> -->
    </div>
    <div id="tripMap" v-if="showButton">
      <el-button @click="addTripButton" style="margin: 10px;" size="small" >新增</el-button>
      <el-button @click="addBatchList" style="margin: 10px;" size="small" v-if="!dataSoureSwitch">保存</el-button>
      <el-button @click="showdTop" style="margin: 10px;" size="small">开始绘画地图</el-button>
      <el-table :data="tableData" style="width: 100%;">
        <!-- <el-table-column width="100" prop="sort" label="排序" align="center">
          <template slot-scope="scope">
            <el-input-number
              class="edit-input"
              v-model="scope.row.sort"
              size="mini"
              controls-position="right"
              style="width: 80px;"
            />
          </template>
        </el-table-column>-->
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          fixed
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click.native.prevent="deleteRow(scope.$index, tableData)"
            >删除</el-button>
          </template>
        </el-table-column>
        <el-table-column  prop="edit" label="标记" align="center" fixed>
          <template slot-scope="scope">
            <el-checkbox
              v-model="scope.row.editShows"
              @change="editDictItemButton(scope.row)"
              size="mini"
              border
            ></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="计划时间" prop="time" width="120" align="center" v-if="!dataSoureSwitch">
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.time"
              filterable
              placeholder="请选择"
              @change="(val) => checkChange(val, scope.row)"
              clearable
            >
              <el-option
                v-for="item in dayDataList"
                :key="item.key"
                :label="item.label"
                :value="item.key"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>

        <el-table-column width="180" prop="tripDetail" label="景点" align="center" v-if="!dataSoureSwitch">
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.tripDetail"
              filterable
              placeholder="请选择"
              @change="(val) => getTripButton(val, scope.row)"
              value-key="uniqueId"
            >
              <el-option
                v-for="item in tripDetailList"
                :key="item.uniqueId"
                :label="item.detailName"
                :value="item"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>

        <el-table-column prop="address" align="center" label="地址" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-input v-model="scope.row.address" size="mini" border></el-input>
          </template>
        </el-table-column> 
        <el-table-column prop="latitude" label="经度"  align="center" >
          <template slot-scope="scope">
            <el-input v-model="scope.row.latitude" size="mini" border></el-input>
          </template>
        </el-table-column>
        <el-table-column  prop="longitude" label="纬度"  align="center" >
          <template slot-scope="scope">
            <el-input v-model="scope.row.longitude" size="mini" border></el-input>
          </template>
        </el-table-column>
        <!-- <el-table-column width="110" prop="budgetCost" label="费用"   align="center">
          <template slot-scope="scope">
            <el-input-number v-model="scope.row.address"    style="width: 100px;" size="mini" border controls-position="right" />
          </template>
        </el-table-column>-->
      </el-table>
    </div>
    <div id="container"></div>
    <div id="panel"></div>
  </div>
</template>
<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import screenfull from "screenfull";
import {
  getItineraryDetailListApi,
  batchItineraryDataList
} from "@/api/life/trip/itineraryDetail";
import { getDetailListApi } from "@/api/life/trip/tripDetail";
export default {
  name: "Mapview",
  data() {
    return {
      map: null,
      resultList: [],
      //地图搜索的列表页
      pagination: {
        pageIndex: 1,
        pageSize: 10,
        total: 0
      },
      // 默认不全屏
      isFullscreen: false,
      showButton: false,
      tableData: [],
      newTop: [],
      riding: {},
      //地图标记变量
      marker: null,
      //搜索
      form: {
        lng: "114.329268", //经纬度设置的默认值
        lat: "30.578576", //经纬度设置的默认值
        keywords: "" //搜索关键字
      },
      cityinfo: "", //IP定位城市,
      uniqueIds: null,
      makrerList: [],
      dayDataList: [],
      tripDetailList: [],
      dataSoureSwitch:true,
      inputSwitch:true
    };
  },
  created() {},
  mounted() {
    this.initAMap();
  },
  methods: {
    showdTop() {
      return this.$message.error("暂时不开放该功能");
      this.tableData.map(item => {
        if (item.checked) {
          this.newTop.push(item);
        }
      });
      this.againDring(this.newTop);
    },
    // removeMarker() {
    //   this.makrerList.forEach(item => {
    //       console.log(item)
    //        this.map.remove(item.mark);
    //     });
    //     this.makrerList=[]
    // },
    // clearButton(row) {
    //   let categoryLists = this.makrerList.filter(item => item.id == row.id);
    //   this.map.remove(categoryLists[0].mark);
    // },
    editDictItemButton(row) {
      if (row.latitude == null || row.longitude == null) {
        row.editShows = false;
        return this.$message.error(
          "该景点没有标记经纬度,请补齐信息"
        );
      }
      console.log("添加地图", row.latitude, row.longitude);
      if (row.editShows) {
        console.log("添加地图");
        //添加地图
        var _that = this;
        // if (row.checked) {
        let marker = new AMap.Marker({
          position: [row.latitude, row.longitude], //位置
          // content: "第一步",
          // offset: new AMap.Pixel(-10, -10),
          // icon: "//vdata.amap.com/icons/b18/1/2.png", // 添加 Icon 图标 URL
          zoom: 13
        });
        _that.map.add(marker); //添加到地图
        var marss = {
          id: row.id,
          mark: marker
        };
        this.makrerList.push(marss);
      } else {
        //取消点位
        console.log("取消点位");
        let categoryLists = this.makrerList.filter(item => item.id == row.id);
        this.map.remove(categoryLists[0].mark);
        this.makrerList = this.makrerList.filter(item => item.id != row.id);
      }
    },
    changeShowButton() {
      this.showButton = !this.showButton;
    },
    againDring(row) {
      let a = [];
      row.map(item => {
        console.log(item.lat, item.lon);
        a.push(new AMap.LngLat(item.lat, item.lon));
      });
      if (this.riding) {
        this.riding.clear();
      }

      var riding = new AMap.Driving({
        map: this.map,
        panel: "panel"
      });

      // riding.search(
      //   a
      //  , function (status, result) {
      //   // result 即是对应的驾车导航信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_DrivingResult
      //   if (status === 'complete') {
      //     console.log('绘制驾车路线完成')
      //   } else {
      //     console.error('获取驾车数据失败：' + result)
      //   }
      // })
      riding.search([116.486784, 39.999944], [116.397755, 39.903179], function(
        status,
        result
      ) {
        // result 即是对应的驾车导航信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_DrivingResult
        if (status === "complete") {
          console.log("绘制驾车路线完成");
        } else {
          console.log("获取驾车数据失败：" + result);
        }
      });

      //步行 每天只能用100次
      // riding.search(
      //   [
      //     //  new AMap.LngLat(116.379028, 39.865042), new AMap.LngLat(116.427281, 39.903719)
      //     { keyword: "临泓路６号院", city: "北京" },
      //     { keyword: "景泰", city: "北京" },
      //     { keyword: "龙潭公园", city: "北京" }
      //   ],
      //   function (status, result) {
      //     // result即是对应的骑行路线数据信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_RidingResult
      //     if (status === "complete") {
      //       console.log("绘制骑行路线完成");
      //     } else {
      //       console.log("骑行路线数据查询失败" + result);
      //     }
      //   }
      // );
    },
    initAMap() {
      window._AMapSecurityConfig = {
        securityJsCode: "4bb07b3af35b509039bd3f2079721ae5"
      };
      AMapLoader.load({
        key: "8818ad4ac69d8755095896525cf2e3ce", //设置您的key
        version: "1.4.15",
        plugins: [
          "AMap.PlaceSearch", //地点搜索服务
          "AMap.Autocomplete", //输入提示
          "AMap.Riding", //骑车规划
          "AMap.Driving", //骑车规划
          "AMap.ToolBar", //添加平移工具
          "AMap.Scale", //比例尺控件
          "AMap.MapType", //类别切换控件
          "AMap.Geolocation", //定位
          "AMap.CitySearch", //获取当前城市
          "AMap.Geocoder", //地理编码与逆地理编码服务
          "AMap.Transfer", //公交路线
          "AMap.StationSearch" //公交站点查询服务
        ],
        AMapUI: {
          version: "1.1",
          plugins: []
        }
      })
        .then(AMap => {
          var _that = this;
          _that.map = new AMap.Map("container", {
            // viewMode: "3D",
            zoom: 5,
            // zooms: [2, 22],
            center: [105.602725, 37.076636]
          });
          //添加平移工具
          _that.map.addControl(new AMap.ToolBar());
          // 在图面添加比例尺控件，展示地图在当前层级和纬度下的比例尺 AMap.Scale
          this.map.addControl(new AMap.Scale());
          // 在图面添加类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
          this.map.addControl(new AMap.MapType());
          //  在图面添加定位控件，用来获取和展示用户主机所在的经纬度位置
          // this.map.addControl(new AMap.Geolocation());
          this.map.addControl(new AMap.Transfer());
          // map.addControl(new AMap.StationSearch());

          //添加地点到地图方法
          //   _that.marker = new AMap.Marker({
          //     position: [e.lnglat.getLng(), e.lnglat.getLat()], //位置
          //   })
          //   _that.map.add(_that.marker) //添加到地图

          // 增加地图点击搜索
          // var clickHandler = function (e) {
          //   if (_that.marker != null || _that.marker != undefined) {
          //     _that.map.remove(_that.marker)
          //   }
          //   _that.marker = new AMap.Marker({
          //     position: [e.lnglat.getLng(), e.lnglat.getLat()], //位置
          //   })
          //   _that.map.add(_that.marker) //添加到地图
          //   _that.form.lng = e.lnglat.getLng()
          //   _that.form.lat = e.lnglat.getLat()
          //   //这里还需要重新调用查询经纬度
          //   //根据经纬度查询地址
          //   _that.regeoCode()
          // }
          // _that.map.on('click', clickHandler)
        })
        .catch(e => {
          console.log(e);
        });
    },
    click() {
      // 使用screenfull.enabled方法判断是否支持screenfull
      // 若不允许进入全屏，发出不允许提示
      if (!screenfull.isEnabled) {
        this.$message({
          message: "该浏览器不支持全屏",
          type: "warning"
        });
        return false;
      }
      // 调用 screenfull.toggle() 切换全屏与非全屏
      screenfull.toggle();
    },
    change() {
      this.isFullscreen = screenfull.isFullscreen;
    },
    // 注册事件
    init() {
      if (screenfull.enabled) {
        screenfull.on("change", this.change);
      }
    },
    // 移除注册的事件
    destroy() {
      if (screenfull.enabled) {
        screenfull.off("change", this.change);
      }
    },
    async regeoCode() {
      var lnglat = this.form.lng + "," + this.form.lat;
      var _that = this;
      this.$http
        .get(
          "https://restapi.amap.com/v3/geocode/regeo",
          {
            params: {
              // output: XMLDocument,
              location: lnglat,
              key: "6d3f349d4cb7c6777d35d3b02881ec87",
              // extensions: all,
              radius: 1000
            }
          },
          {
            emulateJSON: true
          }
        )
        .then(result => {
          console.log(result);
          var address = result.data.regeocode.formatted_address;
          _that.form.keywords = address;
          _that.cityinfo = address;
        });
    },
    onSearch(value) {
      var _this = this;
      this.form.keywords = value;
      var autoOptions = {
        city: _this.cityinfo,
        pageSize: _this.pagination.pageSize, //每页结果数,默认10
        pageIndex: _this.pagination.pageIndex //请求页码，默认1
      };
      var placeSearch = new AMap.PlaceSearch(autoOptions);
      placeSearch.search(_this.form.keywords, function(status, result) {
        // 搜索成功时，result即是对应的匹配数据
        _this.resultList = result.poiList.pois;
        _this.pagination.pageSize = result.poiList.pageSize;
        _this.pagination.pageIndex = result.poiList.pageIndex;
        _this.pagination.total = result.poiList.count;
        var pois = result.poiList.pois;
        for (var i = 0; i < pois.length; i++) {
          var poi = pois[i];
          _this.marker = new AMap.Marker({
            position: [poi.location.lng, poi.location.lat], // 经纬度对象，也可以是经纬度构成的一维数组
            title: poi.name
          });
          _this.form.keywords = poi.name;
          _this.cityinfo = poi.name;
        }
      });
    },
    tripRutren() {
      this.tableData = [];
      this.$parent.amapFromShow = !this.$parent.amapFromShow;
    },
    initData(row,type) {
      if(type==1){
        this.dataSoureSwitch=false
      }
      this.uniqueIds = row.uniqueId;
      this.getTableData();
      this.dayInitList(row.day, row.early);
      this.getTripDetailDataListButton(row);
    },
    getTableData() {
      var data = {
        itineraryId: this.uniqueIds,
        isDistinct: false
      };
      getItineraryDetailListApi(data).then(res => {
        this.tableData = res.result;
      });
    },
    getTripDetailDataListButton(row) {
      getDetailListApi({ tripId: row.tripId }).then(res => {
        this.tripDetailList = res.result;
      });
    },

    dayInitList(day, early) {
      let i = 1;
      if (1 == early) {
        i = 0;
      }
      let dataList = [];
      for (i; i <= day; i++) {
        var dayData = {
          key: i,
          label: "第" + i + "天"
        };
        dataList.push(dayData);
      }
      this.dayDataList = dataList;
    },
    checkChange(val, row) {
      console.log(val, row);
    },
    getTripButton(val, row) {
      //需要清空之前的按钮跟标记
      let categoryLists = this.makrerList.filter(item => item.id == row.id);
      console.log(categoryLists);
      if (categoryLists.length != 0 && categoryLists != undefined) {
        this.map.remove(categoryLists[0].mark);
        this.makrerList = this.makrerList.filter(item => item.id != row.id);
      }
      //row  是这条数据
      this.$set(row, "tripDetailId", val.uniqueId);
      this.$set(row, "address", val.address);
      this.$set(row, "latitude", val.latitude);
      this.$set(row, "longitude", val.longitude);
      this.$set(row, "budgetCost", val.avgBudget);
      this.$set(row, "name", val.name);
      this.$set(row, "phone", val.phone);
      this.$set(row, "editShows", false);
    },

    addBatchList() {
      batchItineraryDataList({
        itineraryId: this.uniqueIds,
        tripDetailList: this.tableData
      }).then(res => {
        this.getTableData();
      });
    },
    addTripButton() {
      var trip = {
        editShows: false,
        itineraryId: this.uniqueIds
      };
      this.tableData.push(trip);
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
    inputDataButton(){
      this.showButton = !this.showButton;
    }
  }
};
</script>
<style  lang="scss" scoped>
.home_div {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
  position: relative;
}

#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
}

#panel {
  position: fixed;
  background-color: white;
  max-height: 90%;
  overflow-y: auto;
  top: 10px;
  right: 10px;
  width: 280px;
}

#panel .amap-lib-driving {
  border-radius: 4px;
  overflow: hidden;
}

#tripMap {
  position: absolute;
  background-color: red;
  max-height: 90%;
  width: 25%;
  z-index: 999;
  left: 85px;
  // margin: 0px 245px;
}

</style>